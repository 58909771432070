import React from "react"
import { ProductSelectionStyles } from "./styles/ProductSelectionStyles"

import Orbitz from "../images/orbitz.png"
import K1017CompactImage from "../images/k10-compact-17d-padassist.png"
import K1020StandardImage from "../images/K10-STANDARD-20D-TRACTION-w200.png"
import K12Image from "../images/k-12-image.jpg"
import K16Image from "../images/k-16-image.jpg"
import K19Image from "../images/k19-beige.jpg"
import K25Image from "../images/k-25-image.jpg"
import K42Image from "../images/k42-main-beige.jpg"
// import K25Image from "../images"
// import K3Image from "../images/k-12-image.jpg"
import K5Image from "../images/k-16-image.jpg"
import K7Image from "../images/KODIAK-K24-32-D-front-WEB-200.png"
import { Link } from "gatsby"

const ProductSelection = () => {
  return (
    <ProductSelectionStyles>
      <div id="product-images">
        {/* ORBITS - K10 and K11 Machines */}
        <Link className="mobile-nav-link" to="/floor-series-page">
          <div className="product-and-description">
            <img src={Orbitz} alt="" />
            <h1>Floor Machines</h1>
          </div>
        </Link>
        {/* <Link className="mobile-nav-link" to="/k10-compact">
          <div className="product-and-description">
            <img src={K1017CompactImage} alt="" />
            <h1>K10 Compact</h1>
          </div>
        </Link> */}
        {/* <Link className="mobile-nav-link" to="/k10-standard">
          <div className="product-and-description">
            <img src={K1020StandardImage} alt="" />
            <h1>K10 Standard</h1>
          </div>
        </Link> */}
        <Link className="mobile-nav-link" to="/k12-series">
          <div className="product-and-description">
            {/* <div id="summer">Coming Fall 2020</div> */}
            <img src={K12Image} alt="" />
            <h1>K12 Series</h1>
          </div>
        </Link>
        <Link className="mobile-nav-link" to="/k16-series">
          <div className="product-and-description">
            <img src={K16Image} alt="" />
            <h1>K16 Series</h1>
          </div>
        </Link>
        <Link className="mobile-nav-link" to="/k19-series">
          <div className="product-and-description">
            {/* <div id="summer">Coming Fall 2020</div> */}
            <img src={K19Image} alt="" />
            <h1>K19 Series</h1>
          </div>
        </Link>
        <Link className="mobile-nav-link" to="/k25-series">
          <div className="product-and-description">
            <img src={K25Image} alt="" />
            <h1>K25 Series</h1>
          </div>
        </Link>
        {/* <Link className="mobile-nav-link" to="/rider-series">
          <div className="product-and-description">
            <img src={K42Image} alt="" />
            <h1>K42 Series</h1>
          </div>
        </Link> */}
      </div>
    </ProductSelectionStyles>
  )
}

export default ProductSelection
