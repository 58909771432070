import styled from "styled-components"

export const IndexStyles = styled.div`
  #hero-image-container {
    position: relative;
    width: 100%;
    height: 600px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    #overlay-info {
      width: 100%;
      position: absolute;
      top: 20%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      color: white;
      z-index: 2;

      h1 {
        font-size: 3em;
        margin: 1rem;
        @media (max-width: 380px) {
          font-size: 2.5em;
        }
      }
      h2 {
        margin: 1rem;
        font-size: 2em;
        @media (max-width: 380px) {
          font-size: 1.5em;
        }
      }
      p {
        margin: 1rem;
        font-size: 1.3em;
        @media (max-width: 380px) {
          font-size: 1em;
        }
      }

      button {
        width: 250px;
        padding: 10px;
        background-color: #f26522;
        border: none;
        color: white;
        border-radius: 5px;
        margin-top: 1.5rem;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  #overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 600px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  /*------VIDEO SECTION----------*/

  #video-section {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: -ms-flexbox;
      height: auto;
    }

    @media (max-width: 1330px) {
      grid-template-columns: 100%;
    }

    #video-info {
      background-color: #606b55;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 50%;
      }

      @media (max-width: 1600px) {
        width: 100%;
      }

      #video-info-content {
        padding: 1.5rem;
        color: whitesmoke;

        @media (max-width: 1330px) {
          text-align: center;
        }

        h1 {
          font-size: 2.5em;
          margin: 0;

          @media (max-width: 380px) {
            font-size: 2em;
          }
        }
        p {
          font-size: 1.2em;

          line-height: 25px;
        }
        button {
          margin-top: 20px;
          padding: 10px;
          width: 250px;
          border: none;
          background: #333;
          font-size: large;
          color: white;

          &:hover {
            cursor: pointer;
            background-color: #f26522;
            color: black;
            transition-duration: 0.5s;
          }
        }
      }
    }
    #video {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 50%;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;

        /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          height: 500px;
        } */
      }
      @media (max-width: 1600px) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`
