import React from "react"
import Layout from "../components/MainLayout"
import HeroImage from "../images/kodiak-hero-image.jpg"
import { IndexStyles } from "../components/styles/IndexStyles"
import Video from "../images/kodiak-spin-removables-compressed.mp4"
import ProductSelection from "../components/ProductSelection"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import Img from "gatsby-image/withIEPolyfill"

const IndexPage = props => {
  // const data = useStaticQuery(graphql`
  //   query Images {
  //     file(relativePath: { eq: "kodiak-cover-shot-resized.png" }) {
  //       id
  //       childImageSharp {
  //         fluid {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <SEO title="Home" />
      <Helmet>
        {/* <title>Kodiak Cleaning Equipment</title> */}
        <description></description>
      </Helmet>
      <Layout>
        <IndexStyles>
          <div>
            <ProductSelection />
          </div>

          <div id="hero-image-container">
            <div id="overlay"></div>
            {/* <Img
              fluid={data.file.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="100% 100%"
            /> */}
            <img src={HeroImage} alt="" />

            <div id="overlay-info">
              <h1>Walk-Behind Floor Scrubbers</h1>
              <h2>Durable. Dependable. Budget Conscious.</h2>
              <p>
                Our floor equipment won't break the bank and will keep scrubbing
                for years. Start scrubbing today!
              </p>
              <Link to="/demo">
                <button>Let's set up a Demo</button>
              </Link>
            </div>
          </div>

          <div id="video-section">
            <div id="video-info">
              <div id="video-info-content">
                <h1>Why Choose Kodiak Scrubbers?</h1>
                <p>
                  Kodiak Cleaning Equipment specializes in durable equipment
                  that will last for years to come. If you are tired of
                  purchasing a new floor scrubber every year, you have come to
                  the right place! We would love to get in contact with you and
                  provide a complimentary Site Survey and Demonstration. Finally
                  a quality alternative to used, reconditioned, demoed or cheap
                  imported products that break and lack local support. Kodiak is
                  assembled with American Labor and has parts and technical
                  support 2nd to none. Comparable in price to mid-lower segment
                  equipment, but not cheaply made. Reduced cost to build due to
                  simplicity in design, leveraging excess production capacity
                  and brought to market with exhibiting resources.
                </p>
                <Link to="/demo">
                  <button type="button">Schedule a Demo</button>
                </Link>
              </div>
            </div>
            <iframe
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/K-Dkkq1Zg7c"
              frameBorder="0"
              allow="accelerometer; fullscreen; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen="allowFullScreen"
            ></iframe>
            {/* <div id="video">
              
              <video className="video" loop autoPlay muted playsInline>
                <source src={Video} type="video/mp4" />
              </video>
            </div> */}
          </div>
        </IndexStyles>
      </Layout>
    </>
  )
}

export default IndexPage
