import styled from "styled-components"

export const ProductSelectionStyles = styled.div`
  position: sticky;
  width: 100%;
  background-color: #e5e5e5;
  /* padding: 1.5rem; */
  /* z-index: 10; */

  #product-images {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    #summer {
      text-align: center;
      position: absolute;
      width: 100px;
      top: 30px;
      left: 60px;
      background-color: rgba(242, 101, 34, 0.5);
      color: white;
      padding: 0.5rem;
      transform: rotate(30deg);

      @media (max-width: 720px) {
        width: 100px;
        left: 80px;
        font-size: 0.6em;
      }

      @media (max-width: 650px) {
        width: 70px;
        left: 50px;
        font-size: 0.6em;
      }

      @media (max-width: 550px) {
        width: 70px;
        left: 50px;
        font-size: 0.6em;
      }
    }

    /* flex-wrap: wrap; */

    .product-and-description {
      position: relative;
      width: 100%;
      img {
        width: 200px;

        /* 150 width when additional machines are added */
        /* width: 150px; */

        margin: 1rem;
        padding: 0;
        transition-duration: 0.5s;
        @media (max-width: 720px) {
          width: 150px;
        }

        @media (max-width: 650px) {
          margin-bottom: 0;
        }

        @media (max-width: 550px) {
          width: 100px;
        }

        /* &:hover {
          transform: translateY(-2px);
          transition-duration: 0.5s;
        } */
      }

      h1 {
        font-weight: bold;
        /* font-size: 1.3em;
        margin-top: -2rem;
        margin-left: -11rem; */
        font-size: 1.3em;
        text-align: center;
        /* padding: 0; */
        @media (max-width: 650px) {
          margin: 0;
        }
        @media (max-width: 720px) {
          font-size: 0.8em;
        }
      }
    }
  }
`
